import { css } from '@emotion/react';

// Fonts
import Light from './calibre.otf';

const calibreLightFont = css`
  @font-face {
    font-family: Calibre;
    font-weight: 400;
    src: url(${Light});
  }
`;

export default calibreLightFont;
