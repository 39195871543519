import { css } from '@emotion/react';

// import camptonFont from '../fonts/campton';
import calibreLightFont from "../fonts/calibreLight";

const reset = css`
  ${calibreLightFont}

  *,
  *:after,
  *:before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%;
    height: 100%;
    font-family: Calibre, 'sans-serif';
  }

  body {
    box-sizing: border-box;
    font-size: 1.6rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  button,
  ul,
  li,
  address,
  a,
  figure,
  input,
  label {
    font-family: inherit;
    font-size: inherit;
  }

  a,
  a:link,
  a:visited {
    text-decoration: none;
    //color: white;
  }

  ul {
    list-style: none;
  }

  address {
    font-style: normal;
  }

  button {
    border: none;
    background: none;
  }
`;

export default reset;
