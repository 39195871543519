import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-image-lightbox/style.css';
import 'react-alice-carousel/lib/alice-carousel.css';

import wrapWithProvider from './wrapWithProvider';

export const wrapRootElement = wrapWithProvider;

// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {

//   const currentPosition = getSavedScrollPosition(location);
//   //const queriedPosition = getSavedScrollPosition({ pathname: `/random` });

//   window.scrollTo(...(currentPosition || [0, 0]));
//     console.log(currentPosition, 'location')
//   return true;
// };

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state)
    location.state.referrer = prevLocation ? `${prevLocation.pathname}` : null;
};
