export interface ThemeType {
  color: {
    primary: string;
    primaryDark: string;
    lightGreen: string;
    accent: string;
    background: string;
    heading: string;
    text: string;
    success: string;
    warning: string;
    error: string;
    black: string;
    white: string;
    overlay: string;
  };
  fontWeight: {
    thin: number;
    extraLight: number;
    light: number;
    regular: number;
    medium: number;
    semiBold: number;
    bold: number;
    black: number;
    heavy: number;
  };
  fontSize: {
    h1: string;
    h2: string;
    h3: string;
    h4: string;
    h5: string;
    h6: string;
    p: string;
    small: string;
    button: string;
    a: string;
    default: string;
  };
  zIndex: {
    below: number;
    default: number;
    overlay: number;
    above: number;
    dropdown: number;
    sticky: number;
    fixed: number;
    modal: number;
  };
}

const theme: ThemeType = {
  color: {
    primary: `hsl(146, 82%, 15%)`,
    primaryDark: `hsl(146, 82%, 5%)`,
    lightGreen: `hsl(146, 82%, 25%)`,
    accent: `hsl(60, 4%, 11%)`,
    background: `hsl(0, 0%, 9%)`,
    heading: `hsl(0, 0%, 0%)`,
    text: `hsl(0, 0%, 0%)`,
    success: `hsl(145, 63%, 50%)`,
    warning: `hsl(46, 100%, 60%)`,
    error: `hsl(0, 100%, 70%)`,
    black: `hsl(0, 0%, 0%)`,
    white: `hsl(0, 0%, 100%)`,
    overlay: `rgba(0, 0, 0, 0.4)`,
  },
  fontWeight: {
    thin: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 800,
    heavy: 900,
  },
  fontSize: {
    h1: `7.2rem`,
    h2: `4.8rem`,
    h3: `3.6rem`,
    h4: `2rem`,
    h5: `2rem`,
    h6: `2rem`,
    p: `1.6rem`,
    small: `1.2rem`,
    button: `1.75rem`,
    a: `2rem`,
    default: `2rem`,
  },

  zIndex: {
    below: -100,
    default: 1,
    overlay: 500,
    above: 700,
    dropdown: 1000,
    sticky: 1020,
    fixed: 1030,
    modal: 1050,
  },
};

export default theme;
